<template>
  <div class="flex flex-col items-center justify-center -mt-3">
    <img src="../../assets/img/404.svg" />
    <p class="mt-4 text-mdh text-grey-dark-1">
      It seems we cannot display this page.
    </p>
    <p class="mb-6 text-grey-dark-1 text-mdh">Go to dahsboard to continue using the platform.</p>
    <Button text="Go to Dashboard" type="primary" size="large" :onClick='moveOn'/>
  </div>
</template>

<script>
import Button from "../../components/Button/Button.vue";
export default {
  name: "404",
  components: { Button },
  methods: {
    moveOn() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
